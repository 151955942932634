import React, { FC } from 'react';
import {render} from 'react-dom';
import { getJwtToken, clearJwtToken } from "./Utils/LoginUtil"
import { setAuthtoken } from "./Services/api/fetch";

const rootEle = document.getElementById('root');

export async function goToLogin() {
    setAuthtoken("");
    clearJwtToken();
    let mApp: Promise<{App:FC<{}>}>;
    mApp = import("./Components/App/App") as any;
    const {App} = await mApp;
    render(<App />, rootEle);
}

export async function bootstrap() {
	const jwtToken = getJwtToken();
    if (document.location.pathname === "/signIn") {
        goToLogin();
        return;
    }
    if (!jwtToken) {
        document.location.pathname = "/signIn";
        goToLogin();
        return
    }

    setAuthtoken(jwtToken);

    let mApp: Promise<{App:FC<{}>}>;
    mApp = import("./Components/App/App") as any;
    const {App} = await mApp;
    render(<App />, rootEle);
}

bootstrap();