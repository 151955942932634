import { qs } from "../../Utils/url";

export const apiHost = new URL(process.env.REACT_APP_API!, document.location.href).toJSON();
const versionPrefix = "v2/";
const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
}
export function setAuthtoken(token: string) {
    if (!token) delete headers.Authorization;
    else headers.Authorization = `Bearer ${token}`;
}

export function setCurrentBusiness(businessId: string) {
    if (!businessId) delete headers.CurrentBusiness;
    else headers.CurrentBusiness = businessId;
}

interface IErrorData<T> extends Error {
    status: number
    error?: {
        statusCode: number;
        message: string;
        errorCode: string;
        errorObject?: T;
    }
}

export async function aFetch<T, TError = any>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    input: string,
    body?: {},
    init?: RequestInit,
): Promise<[(IErrorData<TError>) | undefined, T]> {
    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = (method !== "GET" || !body) ? new URL(apiService, apiHost) : qs(new URL(apiService, apiHost), body);
        const response = await fetch(url.toJSON(), {
            method,
            headers: headers,
            mode: "cors",
            credentials: "include",
            ...((method !== "GET" && body) ? { body: JSON.stringify(body) } : {}),
            ...init
        });
        if (response.ok) {
            try {
                const data = await response.json();
                if (data.status === "success") {
                    // if(data.results.data){
                    //     return [undefined, data.results.data as any as T];   
                    // }
                    return [undefined, data.results as any as T];
                }
                return [Object.assign(new Error(data.message), { status: data.statuscode }), data as any as T];
            } catch (e) {
                console.warn(e);
                return [undefined, null as any as T];
            }
        }

        const err = Object.assign(new Error(response.statusText || String(response.status)), { status: response.status });
        try {
            const e = await response.json();
            return [Object.assign(err, e), null as any];
        } catch {
            return [err, null as any];
        }
    } catch (err) {
        return [err as any, null as any]
    }
}
export async function uploadDocFiles<T, TErrorData = any>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    input: string,
    init?: RequestInit,
): Promise<[(Error & { data?: TErrorData }) | null, T]> {
    const data = new FormData();

    const { "Content-Type": _, ...hs } = headers;

    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = new URL(apiService, apiHost);
        const response = await fetch(url.toJSON(), {
            method,
            headers: hs,
            // credentials: 'include',
            mode: "cors",
            body: data,
            ...init
        });
        if (response.ok) {
            try {
                const data = await response.json();
                if (data.status === "success") {
                    return [null, data.results.token as any as T];
                }
                return [Object.assign(new Error(data.message), { status: data.statuscode }), data as any as T];
            } catch (e) {
                console.warn(e);
                return [null, null as any as T]
            }
        }

        const { error } = await response.json();
        const err = error != null ? new Error(error.message) : new Error(response.statusText || String(response.status));

        try {
            const { data } = await response.json();
            return [Object.assign(err, { data }), null as any];
        } catch {
            return [err, null as any];
        }
    } catch (err) {
        return [err as Error, null as any]
    }
}
export async function uploadFile<T, TErrorData = any>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    input: string,
    base64file: string,
    filename: string,
    isPublic?: boolean,
    init?: RequestInit,
): Promise<[(Error & { data?: TErrorData }) | null, T]> {
    const data = new FormData();
    data.append("multipartFile", await (await fetch(base64file)).blob(), filename);
    const { "Content-Type": _, ...hs } = headers;

    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = new URL(apiService, apiHost);
        const response = await fetch(url.toJSON(), {
            method,
            headers: hs,
            // credentials: 'include',
            mode: "cors",
            body: data,
            ...init
        });
        if (response.ok) {
            try {
                const data = await response.json();
                if (data.status === "success") {
                    return [null, data.results as any as T];
                }
                return [Object.assign(new Error(data.message), { status: data.statuscode }), data as any as T];
            } catch (e) {
                console.warn(e);
                return [null, null as any as T]
            }
        }

        const { error } = await response.json();
        const err = error != null ? new Error(error.message) : new Error(response.statusText || String(response.status));

        try {
            const { data } = await response.json();
            return [Object.assign(err, { data }), null as any];
        } catch {
            return [err, null as any];
        }
    } catch (err) {
        return [err as Error, null as any]
    }
}

export async function uploadFileWithFileType<T, TErrorData = any>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    input: string,
    file: File,
    filename: string,
    isPublic?: boolean,
    init?: RequestInit,
): Promise<[(Error & { data?: TErrorData }) | null, T]> {
    const data = new FormData();
    data.append("multipartFile", file);
    data.append("name", filename);
    data.append("isPublic", isPublic ? String(isPublic) : "false");
    const { "Content-Type": _, ...hs } = headers;

    try {
        const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
        const url = new URL(apiService, apiHost);
        const response = await fetch(url.toJSON(), {
            method,
            headers: hs,
            // credentials: 'include',
            mode: "cors",
            body: data,
            ...init
        });
        if (response.ok) {
            try {
                const data = await response.json();
                if (data.status === "success") {
                    return [null, data.results.token as any as T];
                }
                return [Object.assign(new Error(data.message), { status: data.statuscode }), data as any as T];
            } catch (e) {
                console.warn(e);
                return [null, null as any as T]
            }
        }

        const { error } = await response.json();
        const err = error != null ? new Error(error.message) : new Error(response.statusText || String(response.status));

        try {
            const { data } = await response.json();
            return [Object.assign(err, { data }), null as any];
        } catch {
            return [err, null as any];
        }
    } catch (err) {
        return [err as Error, null as any]
    }
}

// export async function aFetchFile<T, TError = any>(
//     method:"GET"|"POST"|"PUT"|"DELETE",
//     input: string,
//     body ?: {},
//     init?: RequestInit,
// ) : Promise<[(IErrorData<TError>)|undefined, T]> {try {
//         const apiService = (`${versionPrefix}${input}`).replace(/([^:]\/)\/+/g, "$1");
//         const url = (method != "GET" || !body) ? new URL(apiService, apiHost) : qs(new URL(apiService, apiHost), body);
//         const response = await fetch(url.toJSON(), {
//             method,
//             headers: headers,
//             mode:"cors",
//             ...((method != "GET" && body) ? {body: JSON.stringify(body)} : {}),
//             ...init
//         });
//         if (response.ok) {
//             try {
//                 const data = await response.blob();
//                 return [undefined, await toBase64(data)];
//                 // if(data.status == "success"){
//                 //     // if(data.results.data){
//                 //     //     return [undefined, data.results.data as any as T];   
//                 //     // }
//                 //     return [undefined, data.results as any as T];    
//                 // }
//                 // return [Object.assign(new Error(data.message), {status:data.statuscode}), data as any as T];
//             } catch(e) {
//                 console.warn(e);
//                 return [undefined, null as any as T];
//             }
//         }

//         const err = Object.assign(new Error(response.statusText || String(response.status)), {status:response.status});
//         try {
//             const e = await response.json();
//             return [Object.assign(err, e), null as any];
//         } catch {
//             return [err, null as any];
//         }
//     } catch (err) {
//         return [err, null as any]
//     }
// }